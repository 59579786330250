import { Injectable, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { SessionStoreService } from '../stores/session-store.service';
import { PermissionService } from './permission.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoutCountdownComponent } from 'src/app/portal/modals/logout-countdown/logout-countdown.component';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { LogOutReason } from 'src/app/shared/models/logout-reason.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  alertMinutes = 3; // number of minutes left until auth modal appears
  private destroyed$ = new Subject<void>();
  environment = environment;
  timeRemaining = signal(0);

  constructor(
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private sessionStore: SessionStoreService,
    private router: Router,
  ) {
    this.checkTimerCookieExpiration(this.alertMinutes);
  }

  /**
   * Checks the current time against the expiration of the `.timer.portal` cookie every second until the `alertMinutes` mark is up,
   * then show the inactive warning that allows users to extend their session by another 30min. If user takes no action by the time the timer expires then auto-redirect o the log out page
   * Note that there are some external configurations set in the `AuthExtension.cs` file, such as the cookie duration & when the system allows a duration refresh (more than half the cookie duration elapsed)
   * @param alertMinutes the number of minutes before cookie expiration to show the inactive warning
   */
  private checkTimerCookieExpiration(alertMinutes: number): void {
    /** Logout countdown timer. Using signals, when there is a change to the '.timer.portal' cookie,
     * the timeRemaining will reset accordingly */
    interval(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        // Will return NaN if cookie is not found
        const portalTimer = new Date(this.cookieService.get('.timer.portal')).getTime();

        // If user already on logout, clear interval & exit
        if (this.router.url.toLowerCase().includes('logout')) {
          return this.destroyed$.next();
        } else if (isNaN(portalTimer)) { // Else if user cookie is gone, redirect to logout to clear interval
          this.logout('inactive');
        } else { // Remaining timeout logic
          this.timeRemaining.set(
            Math.floor(
              (portalTimer - (new Date().getTime())) / 1000 // time remaining in seconds
            )
          );

          if (this.timeRemaining() <= 1) {
            this.logout('inactive');
          }

          if (this.timeRemaining() === (alertMinutes * 60)) { //activate when we know how we want the modal to perform
            this.inactiveWarning();
          }
        }
      });
  }

  /**
   * Logs the user out
   * @param logoutReason Current reason can be `inactive`, which sets the UI text on the `log-out.component`
   */
  logout(logoutReason?: LogOutReason): void {
    this.destroyed$.next();
    const redirectUri = this.getLogoutRedirectUri(logoutReason);
    this.permissionService.clearAllLocalStorage();
    window.location.href = '/api/Logout/Fig/Redirect?redirectUri=' + window.encodeURIComponent(redirectUri);
  }

  logIn(redirectPath?: string, theme?: string, autoLogin = false): void {
    theme = theme ?? localStorage.getItem('UserGroupId') ?? undefined;
    this.permissionService.clearAllLocalStorage();
    redirectPath = redirectPath ?? '/Portal';
    const redirectUri = this.getLoginRedirectUri(redirectPath);
    let url = `/api/Login/Fig/Redirect?redirectUri=${window.encodeURIComponent(redirectUri)}&autoLogin=${autoLogin}`;
    if (theme) {
      url += `&theme=${theme}`;
    }
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    queryParams.keys.forEach(key => {
      url += `&${key}=${queryParams.get(key) || ''}`;
    });
    window.location.href = url;
  }

  /**
   * There are 2 potential query params when redirecting to lougout - `reason` for logging out (inactive or user's choice) & `userGroupId` which is used for generating the logout page's logo. We need to build to query params into the uri to accommodate possible combinations
   * @param logoutReason Logout `reason` will be built as a query params alongside with `userGroupId` so we can generate the corresponding UI on the `log-out.compnent`
   * @returns redirect uri with query params
   */
  private getLogoutRedirectUri(logoutReason?: LogOutReason): string {
    let redirectUri = '/Logout';
    const userGroupId = this.sessionStore.OnBehalfOfUser?.UserGroupId ?? this.sessionStore.User?.UserGroupId ?? undefined;
    let queryParamsObj: { userGroupId?: string, reason?: LogOutReason };
    let queryParamsString: string | undefined;
    if (userGroupId) {
      queryParamsObj = {
        ...queryParamsObj, ...{ userGroupId: userGroupId }
      };
    }
    if (logoutReason) {
      queryParamsObj = {
        ...queryParamsObj, ...{ reason: logoutReason }
      };
    }
    if (queryParamsObj) {
      queryParamsString = '?';
      for (const [key, value] of Object.entries(queryParamsObj)) {
        queryParamsString += `${key}=${value}&`;
      }
      // Slice the last `&` from the uri string
      queryParamsString = queryParamsString.slice(0, queryParamsString.length - 1);
    }
    redirectUri += queryParamsString ?? '';
    return redirectUri;
  }

  private getLoginRedirectUri(targetPath: string): string {
    const origin = window.location.origin;
    const currentUrl = `${origin}${targetPath}`;
    const homeUrl = `${origin}/Portal`;
    if (currentUrl.includes('Logout') || currentUrl.includes('#')) return homeUrl;
    return currentUrl;
  }

  /** Check if user is authenticated
   * @returns boolean - true if authenticated and cookie not expired, else false
   */
  isAuthenticated(): boolean {
    return !!this.cookieService.get('.timer.portal');
  }

  /**
   * When countdown reaches a set time, open a modal to logoff or continue the session.
   * Note that this doesn't really extend anything unless the elasped time is more than half of the expiration duration currently set to 30min in the backend in `AuthExtension.cs`
   */
  inactiveWarning(): void {
    this.dialog.open<LogoutCountdownComponent, number>(LogoutCountdownComponent, {
      data: this.alertMinutes
    });
  }
}